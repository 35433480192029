import React from 'react';
import { Feature } from '../../components';
import './whatHUGE.css';

function WhatHUGE() {
  return (
	<div className="huge__whathuge section__margin" id="whuge">
		<div className="huge__whathuge-feature">
			<Feature title = "What is The Huge Project?" text="The Huge Project is a Blockchain-based project building on the Telos Network EVM (tEVM). We aim to motivate students for their effort and dedication to building their careers, utilizing an extrinsic motivation system. Following the methods and popularity of the Play to Earn (PTE) games, we are developing our educational version of Learn to Earn (LTE). " />
		</div>
		<div className="huge__whathuge-heading">
			<h1 className="gradient__text">Motivation Explained</h1>
			{/* <p>Explore</p> */}
		</div>
		<div className="huge__whathuge-container">
			<Feature title="Intrinsic Motivation" text="Is internal to a person." />
			<Feature title="Extrinsic motivation" text="Is derived from a more external source and involves a contingent reward." />
			<Feature title="The HUGE theory" text="Motivation, Motivation, Motivation. Reward our valuable users with tokens that they can cash out. Reward our valuable users with NFT which they can sell on a MarketPlace" />
		</div>
	</div>
  )
}

export default WhatHUGE