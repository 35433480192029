import React from 'react';
import { tEVM, telos, cosmos, polygon } from './imports';
import './brand.css';

function Brand() {
  return (
	<>
		<div className="huge__brand-header" id="partners">
			<h1 className="gradient__text-titles">Partners and/or Sponsors</h1>
		</div>
	<div className="huge__brand section__padding">
		<div>
			<img src={tEVM} alt="" />
		</div>
		<div>
			<img src={telos} alt="" />
		</div>
		<div>
			<img src={cosmos} alt="" />
		</div>
		<div>
			<img src={polygon} alt="" />
		</div>
	</div>
	<div className="huge__brand-footer">
		<p>The list above is an indirect partners list. What does that mean? It means The Huge Team utilizes the partner's technology or its economic system to reward our users. </p>
	</div>
	</>
  )
}

export default Brand