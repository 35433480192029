import React from 'react';

import { Footer, Blog, Possibility, Features, WhatHUGE, Header } from './containers';
import { CTA, Brand, Navbar, Roadmap } from './components';
import './App.css';

function App() {
  return (
	<div className='App'>
		<div className='gradient__bg'>
			<Navbar />
			<Header />
		</div>
		<Brand />
		<WhatHUGE />
		<Features />
		<Roadmap />
		<Possibility />
		<CTA />
		<Blog />
		<Footer />
	</div>
  )
}

export default App