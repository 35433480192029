import React from 'react';
import ai from '../../assets/icon-hugeimg1light.png';
import './header.css';

function Header() {
  return (
	<div className="huge__header section__padding" id="home">
		<div className="huge__header-content">
			<h1 className="gradient__text">Let&apos;s Motivate All Students to be Better.</h1>
			<p>Goal-directed activities are started and sustained by motivation. “Motivational processes are personal/internal or external influences that lead to outcomes such as choice, effort, persistence, achievement, and environmental regulation".</p>
		</div>
		<div className="huge__header-image">
			<img src={ai} alt="ai" />
		</div>
	</div>
  )
}

export default Header