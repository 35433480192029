import React from 'react';
import { Feature } from '../../components';
import './features.css';

const featuresData = [
	{
	  title: 'Making a rewards pool',
	  text: 'Every week, our team will allocate the reward pool from our treasure funds.',
	},
	{
	  title: 'Making or Choosing the Content',
	  text: 'Every week, our team will make or choose educational content (video, article) and upload it to our dAPP.',
	},
	{
	  title: 'Show What You Learned',
	  text: 'Read or watch the uploaded content, take a timed quiz, and get the best score.',
	},
	{
	  title: 'Get a Reward',
	  text: 'We will distribute our reward pool among the four leading scores. If multiple people have equal scores. We will randomly select the four winners from among the entries.',
	},
  ];

function Features() {
  return (
	<div className="huge__features section__padding" id="howto">
		<div className="huge__features-heading">
			<h1 className="gradient__text">How would this Work?</h1>
		</div>
		<div className="huge_features-container">
			{featuresData.map((item, index) => (
				<Feature title={item.title} text={item.text} key={item.title + index} />
			))}
		</div>
	</div>
  )
}

export default Features