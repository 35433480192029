import React from "react";

const q4image = () => (
  <div>
    <img
      className="img_logo"
      src='https://thehugeproject.org/q4.svg'
	  alt=""
    />
  </div>
);

export default q4image;