import React from 'react';
import './footer.css';

function Footer() {
  return (
	<div align='center' className='footer'>
      <b>Contact us for Parnership:</b> info@thehugeproject.org
    </div>
  )
}

export default Footer