import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/hugelogo.svg';
import './navbar.css';

function Navbar() {
	const [toggleMenu, setToggleMenu] = useState(false);
	return (
		<div className="huge__navbar">
			<div className="huge__navbar-links">
				<div className="huge__navbar-links_logo">
					<img src={logo} alt="logo" />
				</div>
				<div className="huge__navbar-links_container">
					<p><a href="#home">Home</a></p>
					<p><a href="#whuge">What's HUGE?</a></p>
					<p><a href="#partners">Partners</a></p>
					<p><a href="#howto">How it works?</a></p>
					<p><a href="#roadmap">Roadmap</a></p>
				</div>
			</div>
			<div className="huge__navbar-sign">
				{/* <p>Sign in</p>
				<button type='button'>Sign up</button> */}
			</div>
			<div className="huge__navbar-menu">
				{toggleMenu
					? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
					: <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
				}
				{toggleMenu && (
					<div className="huge__navbar-menu_container scale-up-center">
						<div className="huge__navbar-menu_container-links">
							<p><a href="#home">Home</a></p>
							<p><a href="#whuge">What's HUGE?</a></p>
							<p><a href="#partners">Partners</a></p>
							<p><a href="#howto">How it works?</a></p>
							<p><a href="#roadmap">Roadmap</a></p>
						</div>
						<div className="huge__navbar-menu_container-links-sign">
							{/* <p>Sign in</p>
							<button type="button">Sign up</button> */}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Navbar