import React from 'react';
import ExampleLogo from './ExampleLogo';
import Q4Image from './Q4Image';
import './roadmap.css';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const Roadmap = () => {
    return(
        <div className='roadmap' id="roadmap">
			<h1 className="gradient__text">RoadMap</h1>
            <VerticalTimeline lineColor='#fff'>
                <VerticalTimelineElement className='vertical-timeline-element-roadmap' 
                iconStyle={{background:'transparent'}} 
				icon={<ExampleLogo/>} >
                    <h3 className="gradient__text_b">
                        Q3 2022
                    </h3>
                    <ul>
						<li>Creating Our Social Media ✅</li>
						<li>Initial treasure funds ✅</li>
						<li>Web V0.1 release ✅</li>
						<li>Building our Comunity 🛠</li>
						<li>Parnership and sponsors 🔍</li>
                    </ul>
                </VerticalTimelineElement>

                <VerticalTimelineElement className='vertical-timeline-element-roadmap' 
                iconStyle={{background:'transparent'}} 
				icon={<Q4Image/>}>
                    <h3 className="gradient__text_b">
                        Q4 2022
                    </h3>
                    <ul>
                        <li>Make our token Smart Contract</li>
						<li>Building our reward system</li>
						<li>Deploy our V0.1 dAPP</li>
						<li>Targeting educators to support our system</li>
						<li>Targeting goverments to support our system</li>
                    </ul>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    )
}

export default Roadmap