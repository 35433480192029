import React from "react";

const examplelogo = () => (
  <div>
    <img
      className="img_logo"
      src='https://thehugeproject.org/q3.svg'
	  alt=""
    />
  </div>
);

export default examplelogo;